import type { FC } from 'react';
import React from 'react';

import { Text } from '@atlaskit/primitives';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { DateLoader, EmojiLoader, MentionLoader, StatusLoader } from './nodes';

interface ElementInterface {
	adfEntities: (ADFEntity | undefined)[];
	cloudId: string;
	userId: string;
}

export const MiniRenderer: FC<ElementInterface> = ({ adfEntities, cloudId, userId }) => {
	return (
		<Text as="span" color="inherit" size="large">
			{adfEntities.map((el: ADFEntity | undefined, idx) => {
				switch (el?.type) {
					case 'date':
						return <DateLoader timestamp={el?.attrs?.timestamp} />;
					case 'emoji':
						return (
							<EmojiLoader
								id={el?.attrs?.id}
								shortName={el?.attrs?.shortName}
								cloudId={cloudId}
								userId={userId}
							/>
						);
					case 'mention':
						return <MentionLoader name={el?.attrs?.text} isUserSelf={el?.attrs?.id === userId} />;
					case 'status':
						return (
							<StatusLoader
								text={el?.attrs?.text}
								color={el?.attrs?.color}
								isBold={el?.attrs?.style === 'bold'}
							/>
						);
					default:
						return (
							<Text as="span" color="inherit" size="large" key={idx}>
								{el?.text ||
									el?.attrs?.text ||
									// smartlink content title
									el?.attrs?.__confluenceMetadata?.contentTitle ||
									''}
							</Text>
						);
				}
			})}
		</Text>
	);
};
